:root {
    --client-fnt-color: #120d0d;
    --client-header-color: #032580;
    --client-btn-color: #032580;
    --client-btn-inactive: #999;
    --client-btn-border-color: #032580;
    --client-btn-hover: #2F4580;
    --client-clr-warning: darkred;
    --client-clr-selected: rgba(3, 37, 128, 0.2);
    --client-thead-color: #e6f2ff;
}
