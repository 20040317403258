/* Loading spinner */
.spinner {
    position: absolute;
    left: calc(50% - 100px);
    top: calc(50% - 15px);
}

/* App Menu */
#AppSidebar, #UserProfile {
    display: flex;
    flex-direction: column;
    float: left;
    min-width: 100px;
    max-width: 100px;
    height: 100%;
    background: #f0f0f0;
}
#UserProfile {
    padding-top: 6px;
}

/* Components */
#SummaryStats {
    height: 350px;
}
#SummaryStats td {
    font-size: 16px;
}
#ClosePopup {
    position: absolute;
    right: 10px;
}

.app-title {
    position: absolute;
    display: inline-block;
    margin-left: 8px;
    top: 32px;
    color: var(--client-fnt-color);
}
.app-subtitle {
    font-size: 24px;
    color: var(--client-btn-color);
}
.app-version {
    position: absolute;
    display: inline-block;
    top: 65px;
    right: 44px;
    color: #b0b0b0;
    font-size: 12px;
}
.client-title {
    position: absolute;
    top: 44px;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.form-logo {
    height: 80px;
    width: auto;
}
.module-logo {
    height: 14px;
    width: auto;
    margin-right: 3px;
}
.padding {
    padding-left: 8px;
    padding-right: 8px;
}
.info-icon {
    margin-top: 2px;
    margin-right: 8px;
    display: inline-block;
    float: right;
    color: var(--client-btn-color);
    cursor: pointer;
}
.field-icon {
    height: 38px;
    width: auto;
}

.help-title {
    color: var(--client-btn-color);
}
.help-text {
    font-size: 12px;
    text-align: center;
    color: var(--client-btn-color);
}

.navigate-main {
    position: absolute;
    top: 0;
    right: 20px;
    display: inline-block;
}
.navigate-sub {
    position: absolute;
    top: 12px;
    right: 50px;
    display: inline-block;
}
.navigate-search {
    position: absolute;
    top: 0;
    right: 150px;
    display: inline-block;
}
.navigate-report {
    position: absolute;
    top: 0;
    right: 20px;
}
.reload {
    position: absolute;
    top: 11px;
    right: 10px;
    display: inline-block;
}
.export {
    position: absolute;
    top: 0;
    right: 20px;
    display: inline-block;
}
th.sort-up:hover::before {
    position: absolute;
    top: 6px;
    right: 0;
    color: #888;
    content: "\2191";
    cursor: pointer;
}
th.sort-down:hover::before {
    position: absolute;
    top: 6px;
    right: 0;
    color: #888;
    content: "\2193";
    cursor: pointer;
}
.selectable {
    cursor: pointer;
}
td.value.text-success,
td.value.text-danger {
    padding-top: 6px;
    font-size: 22px;
}
td.value.text-success {
    color: #357a32!important;
}
td.value.text-danger {
    color: #ca3120!important;
}

.timeframes {
    position: absolute;
    right: 40px;
    height: 100px;
}
.timeframes span {
    color: #999;
    display: inline-block;
    float: right;
}
.timeframes .dropdown-section {
    border-top: 1px solid #aaa;
}
.timeframes .dropdown-toggle,
.timeframes .dropdown-menu {
    width: 290px;
}

.table-striped > tbody > tr#utilization:nth-of-type(odd) > *,
.table-striped > tbody > tr#utilization:nth-of-type(even) > * {
    --bs-table-accent-bg: rgba(204, 229, 255, 1);
    background-color: rgba(204, 229, 255, 1);
}
.table-striped > tbody > tr#care:nth-of-type(odd) > *,
.table-striped > tbody > tr#care:nth-of-type(even) > *,
.table-striped > tbody > tr#census:nth-of-type(odd) > *,
.table-striped > tbody > tr#census:nth-of-type(even) > * {
    --bs-table-accent-bg: rgba(230, 242, 255, 1);
    background-color: rgba(230, 242, 255, 1);
}
.table-striped > tbody > tr.selected#care:nth-of-type(odd) > *,
.table-striped > tbody > tr.selected#care:nth-of-type(even) > *,
.table-striped > tbody > tr.selected#census:nth-of-type(odd) > *,
.table-striped > tbody > tr.selected#census:nth-of-type(even) > * {
    --bs-table-accent-bg: var(--client-clr-selected);
    background-color: var(--client-clr-selected);
}

#utilization td {
    font-size: 18px;
    font-weight: bold;
}

.toast {
    position: absolute;
    left: 50%;
    top: 40%;
    -ms-transform: translate(-50%, -40%);
    transform: translate(-50%, -40%);
    z-index: 10;
}
.toast-header {
    color: white;
    background-color: var(--client-header-color);
}
.toast-body {
    text-align: left;
    background-color: white;
    border-radius: 5px;
}

/* Tooltips */
.tip-custom {
    padding: 0 10px;
}
.tip-title {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}
.tip-last-year {
    color: #31a5a4;
    margin: 0;
    padding: 0;
}
.tip-this-year {
    color: #3399ff;
    margin: 0;
    padding: 0;
}

/* category */
.category {
    margin-right: 6px;
}
.category.addiction {
    color: #800000;
}
.category.care_initiative {
    color: #FF7F3F;
}
.category.culture {
    color: #FF0000;
}
.category.emotional_health {
    color: #FF00FF;
}
.category.first_responders {
    color: #0000FF;
}
.category.manager_training {
    color: #FFFF00;
}
.category.wellness {
    color: #008080;
}
.category.post {
    color: #00FF00;
}
.category.story {
    color: #00FFFF;
}

/* React-Bootstrap button style changes */
.btn-pointer {
    cursor: pointer;
}
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--client-btn-color);
    --bs-btn-border-color: var(--client-btn-color);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--client-btn-hover);
    --bs-btn-hover-border-color: var(--client-btn-color);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--client-btn-color);
    --bs-btn-active-border-color: var(--client-btn-color);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--client-btn-color);
    --bs-btn-disabled-border-color: var(--client-btn-color);
}
.btn-forgot {
    --bs-btn-color: var(--client-btn-color);
    --bs-btn-bg: #FFF;
    --bs-btn-border-color: #FFF;
    --bs-btn-hover-color: var(--client-btn-hover);
    --bs-btn-hover-bg: #f9f7fc;
    --bs-btn-hover-border-color: #f0f0f0;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #f0f0f0;
    --bs-btn-active-border-color: #f0f0f0;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #f0f0f0;
    --bs-btn-disabled-border-color: #f0f0f0;
}
