:root {
    --client-fnt-color: #120d0d;
    --client-header-color: #8138FF;
    --client-btn-color: #8138FF;
    --client-btn-border-color: #8138FF;
    --client-btn-hover: #ab8cff;
    --client-btn-inactive: #999;
    --client-clr-warning: darkred;
    --client-clr-selected: rgba(171, 140, 255, 0.2);
    --client-thead-color: #e6f2ff;
}
